import {dictionariesHelper} from "@/helpers/dictionariesHelper";
import moment from "moment";
import phoneFilter from "@/helpers/filters/phoneFilter";

const tableColsKindergartens = [
    {
        text: "ID",
        value: "id",
        width: 25,
        userRole: [60, 70],
        sort: true
    },
    {
        text: "Город",
        value: "city",
        renderFunction: value =>
            `<div style="display: inline-grid"><span>${value?.city_details?.name ||
            value?.city?.name}</span>
              <span style="background-color: #95c23d; border-radius: 15px; color: #fff;  padding: 2px 5px">${
                dictionariesHelper.timezone[
                value?.city_details?.timezone || value?.city?.timezone
                    ]
            }</span></div>`,
        userRole: [70]
    },
    {
        text: "Название",
        value: "name",
        userRole: [60, 70],
        sort: true
    },

    {
        text: "Адрес",
        value: "address",
        userRole: [60, 70],
        sort: true,
        width: 20
    },
    {
        text: "Администратор",
        renderFunction: value =>
            value?.org_admins
                ?.map(el => el.full_name + ", " + phoneFilter.execute(el?.phone_number))
                ?.join("\n"),
        userRole: [10, 20, 21, 30, 31, 40, 50,],
        src: "/img/table_icons/admin-with-cogwheels.svg",
        width: 20,
        sort: true
    },
    {
        text: "Контроллеров",
        value: "controllers_count",
        src: "/img/table_icons/tabbar__icon_5.svg",
        width: 20,
        userRole: [60, 70]
    },
    {
        text: "Пользователей",
        value: "users_count",
        src: "/img/table_icons/tabbar__icon_9.svg",
        width: 20,
        userRole: [60, 70]
    },

    {
        text: "Оплата пользователей (месяц/год)",
        // value: "payments_sum_last_year",
        renderFunction: value =>
            `${value.payments_sum_last_month || "-"} ` + "/" + ` ${value.payments_sum_last_year || "-"}`,
        userRole: [60, 70]
    },
    // value.payments_sum_last_month != 0 ? `${value.payments_sum_last_month}` : "-" + "/" + value.payments_sum_last_year != 0 ? `${value.payments_sum_last_year}`: "-",
    {
        text: "Обслуживающая организация",
        renderFunction: value =>
            `${value.object?.service_organization?.name || "-"}`,
        userRole: [60, 70],
        src: "/img/table_icons/setting__title_icon.svg",
        width: 20,
        sort: true
    },

    {
        text: "Дилер",
        value: "diller_name",
        userRole: [70],
        src: "/img/table_icons/tabbar__icon_1.svg",
        width: 20,
        sort: true
    },
    {
        text: "Дата создания",
        value: "created_at",
        userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
        renderFunction: value => {
            return moment(value.created_at * 1000).format("DD.MM.YYYY HH:mm");
        },
        sort: true
    }
];

export default tableColsKindergartens;
